import styled from "styled-components";
import { Dimensions } from "../GlobalStyles/Dimensions";

export const FullscreenBodyRoot = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding-top: ${Dimensions.NavigationHeight}px;
    box-sizing: border-box;
`;

export const ScrollableBodyRoot = styled.div`
    display: block;
    padding-top: ${Dimensions.NavigationHeight}px;
    box-sizing: border-box;
`;
